<template>
  <div>
    <span class="text-center table-edit-icon" @click="showModal">
      <i class="text-center material-icons">edit</i>
    </span>
    <div class="centerx">
      <vs-popup class="holamundo editModal" title="Update organization" :active.sync="popupActive">
        <form>
          <div class="vx-row">
            <!-- LEFT SIDE -->
            <div class="vx-col mx-auto">
              <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/2 w-full text-right align-self-center">
                  <h5 class="mb-0">Role Name*</h5>
                </div>
                <div class="vx-col sm:w-1/2 w-full">
                  <div class>
                    <vs-select
                      v-model="form.role_name"
                      v-validate="'required'"
                      placeholder="Role Name"
                      name="Role Name"
                      class="select-large mt-5 w-full"
                    >
                      <vs-select-item
                        :key="index"
                        :value="item.role_id"
                        :text="item.role_name"
                        v-for="(item,index) in roles"
                      />
                    </vs-select>
                  </div>
                </div>
              </div>
              <!-- <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/2 w-full text-right align-self-center">
                  <h5 class="mb-0">Organization Name *</h5>
                </div>
                <div class="vx-col sm:w-1/2 w-full">
                  <div class>
                    <vs-input
                      disabled
                      placeholder="Organization Name"
                      name="oraganization name"
                      v-model="form.organization_name"
                      class="w-full"
                    />
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('organization name')"
                    >{{ errors.first('organization name') }}</span>
                  </div>
                </div>
              </div>-->
              <!-- <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/2 w-full text-right">
                  <h5 class="mb-0">Description</h5>
                </div>
                <div class="vx-col sm:w-1/2 w-full">
                  <vs-textarea
                    v-model="form.description"
                    placeholder="Short description"
                    class="mb-0"
                    rows="4"
                  />
                </div>
              </div>-->
              <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/2 w-full text-right">
                  <h5 class="mb-0">Status*</h5>
                </div>
                <div class="vx-col sm:w-1/2 w-full">
                  <b-form-checkbox
                    value="1"
                    unchecked-value="2"
                    v-model="form.userrole_active"
                    switch
                  ></b-form-checkbox>
                </div>
              </div>
            </div>
          </div>

          <!-- SUBMIT BUTTON -->
          <div class="vx-row">
            <div class="vx-col mx-auto">
              <!-- :disabled="submitStatus" -->
              <vs-button
                :disabled="submitStatus"
                type="filled"
                @click.prevent="submitForm"
                class="mr-3"
              >Save</vs-button>

              <!-- <vs-button color="warning" type="border" class @click.prevent="clearForm">Reset</vs-button> -->
            </div>
          </div>
        </form>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import designationService from "@/services/designationService.js";
import UserRolesService from "@/services/userRolesService.js";
export default Vue.extend({
  data() {
    return {
      switch1: true,
      submitStatus: true,
      form: {
        role_id: null,
        organization_id: null,
        organization_name: "",
        role_name: "",
        userrole_active: "1",
      },
      companies: [],
      popupActive: false,
      params: null,
      value: null,
      count: 0,
    };
  },
  watch: {
    form: {
      handler: function(newVal, oldVal) {
        if (this.count > 0) {
          this.submitStatus = false;
        }
        this.count++;
      },
      deep: true,
    },
  },
  beforeMount() {
    this.value = this.params.value;
    this.form = this.params.data;
  },
  methods: {
    showModal() {
      // console.log(this.params.data.department_active, "fsdfsd");

      // console.log("afsadf");
      this.popupActive = true;
    },
    submitForm() {
      this.$validator.validateAll().then(result => {
        this.$vs.loading();
        if (result) {
          this.submitStatus = true;
          let id = this.form.designation_id;
          let payload = {
            role_id: this.form.role_id,
            organization_id: this.form.organization_id,
            // organization_name: this.form.organization_name,
            role_name: this.form.role_name,
            userrole_active: this.form.userrole_active,
          };
          console.log(
            "payload :",
            this.params.data.designation_id,
            payload,
          );
          UserRolesService.editUserRole(payload, id)
            .then(response => {
              this.$vs.loading.close();
              this.submitStatus = false;
              const { data } = response;
              if (!data.error||data.Status == true ) {
                this.$vs.notify({
                  title: "Updated!",
                                                   text: data.Message,

                  iconPack: "feather",
                  icon: "check_box",
                  color: "success",
                });
                this.popupActive = false;
                this.clearForm();
              } else {
                this.$vs.loading.close();
                console.log("data :", data);
                this.$vs.notify({
                  title: "Error!",
                                                  text: data.Message,

                  iconPack: "feather",
                  icon: "check_box",
                  color: "warning",
                });
              }
            })
            .catch(error => {
              this.$vs.loading.close();
              this.submitStatus = true;
              console.log("error :", error);
            });
          // if form have no errors
          // alert("form submitted!");
        } else {
          // form have errors
        }
      });
    },
    getAllUserRoles: function() {
      // console.log("RolesService :", RolesService);
      UserRolesService.getAllUserRoles()
        .then(response => {
          const { data } = response;
          if (data.err) {
          } else {
            this.roles = data.data;
          }
        })
        .catch(error => {
          console.log("error :", error);
        });
    },
    clearForm: function() {
      Object.keys(this.form).map((item, index) => {
        this.form[item] = "";
      });
    },
  },

  mounted() {
    this.getAllUserRoles();
  },
});

// import Vue from "vue";

// export default Vue.extend({
//   template: ` {{params.value}}
//          <label class="switch">
//           <input @click="onChangeCheckbox" type="checkbox">
//           <span class="slider round"></span>
//     </label> `,
//   data: function () {
//     return {};
//   },
//   beforeMount() {},
//   mounted() {},
//   methods: {

//   }
// });
</script>
<style>
</style>