<!-- =========================================================================================
    File Name: AgGridTable.vue
    Description: Ag Grid table
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard 
    Author:  
    Author URL:  
========================================================================================== -->
<template>
  <div>
    <!-- ADD USER ROLE  -->
    <div class="vx-row">
      <!-- HORIZONTAL LAYOUT -->
      <div class="vx-col w-full">
        <vx-card class="form-title" title="Add User Role">
          <form>
            <div class="vx-row">
              <!-- LEFT SIDE -->
              <div class="vx-col md:w-3/4 mx-auto">
                <div class="vx-row mb-6">
                  <div class="vx-col sm:w-1/5 w-full text-right align-self-center">
                    <h6 class="mb-0">Role Name*</h6>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <div class>
                      <vs-input
                        v-validate="'required'"
                        name="Role Name"
                        v-model="form.role_name"
                        class="w-full"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Role Name')"
                      >{{ errors.first('Role Name') }}</span>
                    </div>
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div class="vx-col sm:w-1/5 w-full text-right">
                    <h6 class="mb-0">Description</h6>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <vs-textarea v-model="description" class="mb-0" rows="4" />
                  </div>
                </div>
                <div class="vx-row mb-6">
                  <div class="vx-col sm:w-1/5 w-full text-right">
                    <h6 class="mb-0">Status</h6>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <!-- <vs-switch vs-icon-off="2" vs-icon-on="1" v-model="form.department_active" /> -->
                    <b-form-checkbox
                      value="1"
                      unchecked-value="2"
                      v-model="form.userrole_active"
                      name="check-button"
                      switch
                    ></b-form-checkbox>
                  </div>
                </div>
              </div>
            </div>

            <!-- SUBMIT BUTTON -->
            <div class="vx-row">
              <div class="vx-col mx-auto">
                <vs-button
                  :disabled="submitStatus"
                  type="filled"
                  @click.prevent="submitForm"
                  class="mr-3"
                >Submit</vs-button>

                <vs-button color="warning" type="border" class @click.prevent="clearForm">Reset</vs-button>
              </div>
              <!-- <div class="vx-col">
                <div class="excel-btn-1">
                  <vx-tooltip text="Template Excel" position="left">
                    <vs-button
                      type="border"
                      class="py-2"
                      @click.native="onClickSampleDownload(excelurl+`/userrole/excel/sample`)"
                    >
                      <img
                        class="d-inline-flex"
                        src="@/assets/images/icons/excel.svg"
                        height="20"
                        width="20"
                      />
                    </vs-button>
                  </vx-tooltip>
                </div>

                <div class="excel-btn-2">
                  <vx-tooltip text="Import Excel" position="left">
                    <vs-button class="px-4" @click="gridApi.exportDataAsCsv()">
                      <span class="d-inline-flex pr-5 text-white">Import</span>
                      <img
                        class="d-inline-flex"
                        src="@/assets/images/icons/import-excel.svg"
                        height="20"
                        width="20"
                      />
                    </vs-button>
                  </vx-tooltip>
                </div>
              </div>-->
            </div>
          </form>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import contacts from "../../ui-elements/ag-grid-table/data.json";
import DatetimePickerStartEnd from "@/views/components/extra-components/datetime-picker/DatetimePickerStartEnd.vue";
import CompanyService from "@/services/companyService.js";
import UserRolesService from "@/services/userRolesService.js";
import { BASEURL } from "@/config/index.js";

const VxTour = () => import("@/components/VxTour.vue");

import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";

export default {
  components: {
    AgGridVue,
    VxTour,
    DatetimePickerStartEnd,
  },
  data() {
    return {
      excelurl: BASEURL,
      submitStatus: false,
      User: "Select User",
      description: "",
      form: {
        role_name: "",
        userrole_active: "1",
      },
      companies: [],
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      fromDate: null,
      toDate: null,
      configFromdateTimePicker: {
        minDate: new Date(),
        maxDate: null,
      },
      configTodateTimePicker: {
        minDate: null,
      },
      cityOptions: [
        { text: "New York", value: "new-york" },
        { text: "Chicago", value: "chicago" },
        { text: "San Francisco", value: "san-francisco" },
        { text: "Boston", value: "boston" },
      ],
      contacts: contacts,
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("email", null);
      } else
        this.gridOptions.columnApi.setColumnPinned("email", "left");
    },
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi)
          return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },
  methods: {
    onClickSampleDownload: function(URL) {
      window.open(URL, "_blank");
    },
    submitForm() {
      this.$validator.validateAll().then(result => {
        this.$vs.loading();
        if (result) {
          this.submitStatus = true;
          let payload = {
            ...this.form,
            description: this.description,
          };
          console.log("payload :", payload);
          UserRolesService.addUserRole(payload)
            .then(response => {
              this.$vs.loading.close();
              this.submitStatus = false;
              const { data } = response;
              if (!data.error||data.Status == true ) {
                this.$vs.notify({
                  title: "Updated!",
                                                   text: data.Message,

                  iconPack: "feather",
                  icon: "check_box",
                  color: "success",
                });
                this.clearForm();
                setTimeout(() => {
                  eventBus.$emit("refreshTablerole", data);
                }, 1);
              } else {
                this.$vs.loading.close();
                console.log("data :", data);
                this.$vs.notify({
                  title: "Error!",
                                                    text: data.Message,

                  iconPack: "feather",
                  icon: "check_box",
                  color: "warning",
                });
              }
            })
            .catch(error => {
              this.$vs.loading.close();
              this.submitStatus = true;
              console.log("error :", error);
            });
          // if form have no errors
          // alert("form submitted!");
        } else {
          // form have errors
        }
      });
    },
    getCompanyList: function() {
      // console.log("RolesService :", RolesService);
      CompanyService.getAllCompanies()
        .then(response => {
          const { data } = response;
          if (data.err||data.Status == false) {
          } else {
            this.companies = data.data;
          }
        })
        .catch(error => {
          console.log("error :", error);
        });
    },
    clearForm: function() {
      Object.keys(this.form).map((x, index) => {
        /_active/.test(x)
          ? (this.form[x] = "1")
          : (this.form[x] = "");
      });
      this.description = "";
      this.$validator.reset();
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    onFromChange(selectedDates, dateStr, instance) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr, instance) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },
  },
  mounted() {
    this.getCompanyList();
    this.gridApi = this.gridOptions.api;
  },
};
</script>
